import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { memo } from '../util/memo';
import type { User as FirebaseUser, ParsedToken } from 'firebase/auth';
import { User } from 'functions/src/types/firestore/User';
import { ConverterFactory } from 'functions/src/util/firestore/ConverterFactory';
import type { Unsubscribe } from 'firebase/firestore';
import {
  setEncryptedRefreshToken,
  unsetEncryptedRefreshToken,
} from '../util/auth/setEncryptedRefreshToken';
import { Optional } from 'utility-types';
import { findItem, getItem, setItem, removeItem } from '../util/webStorage';
import { minimizeUserData } from '../util/auth/minimizeUserData';
import { isLoading, Loadable } from '../../functions/src/util/isLoading';
import { DEFAULT_DELETED_STRING } from '../../functions/src/util/user/deletedDefaults';
import { mergeFetchedClaims } from '../util/auth/mergeFetchedClaims';
import { createAnonymousUser } from '../util/auth/createAnonymousUser';
import { onlyIdentified } from '../util/auth/onlyIdentified';

export const FIREBASE_USER_LOCAL_KEY_REGEX = /^firebase:authUser/;
export const USER_DATA_LOCAL_KEY = 'firebase:userData' as const;
export const GUEST_USER_ID = 'guest' as const;

export type FirebaseUserLocal = Optional<
  FirebaseUser,
  | 'providerId'
  | 'metadata'
  | 'refreshToken'
  | 'tenantId'
  | 'delete'
  | 'getIdToken'
  | 'getIdTokenResult'
  | 'reload'
  | 'toJSON'
> & {
  claims?: ParsedToken;
};
//  & {
//   apiKey: string;
//   appName: string;
//   createdAt: string;
//   lastLoginAt: string;
// };

export type AuthContextType = {
  /**
   * Please only use user if you need access
   * other fields than user.uid. Otherwise, you should
   * use uid.
   */
  user: Loadable<FirebaseUserLocal>;
  /**
   * userData and uid are null for anonymous users.
   * They are undefined while they are loading.
   */
  userData: Loadable<User<Date>>;
  uid: Loadable<string>;
  /**
   * Please only use userFull if you need access
   * other fields than userFull.uid. Otherwise, you should
   * use uidFull.
   */
  userFull?: FirebaseUserLocal;
  userDataFull?: User<Date>;
  uidFull?: string;
  isUserDeleted?: boolean;
  isSubscribingUserData?: boolean;
  unsubscribeUserData: () => void;
};

export type AuthProviderProps = {
  children: ReactElement;
};

const AuthContext = createContext<AuthContextType>({
  user: undefined,
  userData: undefined,
  uid: undefined,
  userFull: undefined,
  userDataFull: undefined,
  uidFull: undefined,
  isUserDeleted: undefined,
  isSubscribingUserData: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unsubscribeUserData: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProviderUnmemoized = ({ children }: AuthProviderProps) => {
  const [userInternal, setUserInternal] = useState<
    Loadable<FirebaseUserLocal & { _fetchedFromRemote?: boolean }>
  >(findItem(FIREBASE_USER_LOCAL_KEY_REGEX) || undefined);

  const [userDataInternal, setUserDataInternal] = useState<
    Loadable<User<Date>>
  >(getItem(USER_DATA_LOCAL_KEY) || undefined);

  const unsubscribeUserDataInternalRef = useRef<Unsubscribe | null>(null);
  const unsubscribeUserDataInternal = useCallback(() => {
    if (unsubscribeUserDataInternalRef.current) {
      unsubscribeUserDataInternalRef.current();
      unsubscribeUserDataInternalRef.current = null;
    }
  }, []);

  const subscribeUserDataInternal = useCallback(
    async (userId: string) => {
      unsubscribeUserDataInternal();
      const firestoreImport = import('../config/firebase-client/firestore');
      const { firestore } = await firestoreImport;
      const firebaseFirestoreImport = import('firebase/firestore');
      const { doc, onSnapshot } = await firebaseFirestoreImport;

      unsubscribeUserDataInternalRef.current = onSnapshot(
        doc(firestore, `User/${userId}`).withConverter<User<Date>>(
          ConverterFactory.buildDateConverter(),
        ),
        (updatedDoc) => {
          const userUpdated = updatedDoc.data();

          if (!userUpdated?.id) {
            // Waiting for auth-onUserCreate to finish running...
            return;
          }

          setUserDataInternal(userUpdated);
          try {
            const userDataMinimum = minimizeUserData(userUpdated);
            setItem(USER_DATA_LOCAL_KEY, userDataMinimum);
          } catch (error) {
            console.error('Failed to set user data in web storage', error);
          }
        },
        (error) => {
          console.error(error);
        },
      );
    },
    [unsubscribeUserDataInternal],
  );

  useEffect(() => {
    if (userInternal === null) {
      unsubscribeUserDataInternal();

      createAnonymousUser();
    } else if (
      !isLoading(userInternal) &&
      userInternal?._fetchedFromRemote &&
      !userInternal?.isAnonymous
    ) {
      subscribeUserDataInternal(userInternal.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subscribeUserDataInternal,
    unsubscribeUserDataInternal,
    userInternal?.uid,
    userInternal?._fetchedFromRemote,
    userInternal?.isAnonymous,
  ]);

  const subscribeIdTokenChange = useCallback(async () => {
    const authImport = import('../config/firebase-client/auth');
    const { auth } = await authImport;
    const firebaseAuthImport = import('firebase/auth');
    const { onIdTokenChanged } = await firebaseAuthImport;

    return onIdTokenChanged(auth, (firebaseUser) => {
      if (!firebaseUser) {
        unsetEncryptedRefreshToken();
        return;
      }
      setEncryptedRefreshToken();
    });
  }, []);

  const subscribeAuthStateChange = useCallback(async () => {
    const authImport = import('../config/firebase-client/auth');
    const { auth } = await authImport;
    const firebaseAuthImport = import('firebase/auth');
    const { onAuthStateChanged } = await firebaseAuthImport;

    return onAuthStateChanged(auth, async (firebaseUser) => {
      const existingUserData = getItem(USER_DATA_LOCAL_KEY) as
        | User<Date>
        | undefined;

      if (!firebaseUser) {
        const existingUser = findItem(FIREBASE_USER_LOCAL_KEY_REGEX) as
          | FirebaseUserLocal
          | undefined;
        /**
         * We have to also look at USER_DATA_LOCAL_KEY because
         * Firebase will delete FIREBASE_USER_LOCAL_KEY_REGEX
         * when any other tab authenticates and onAuthStateChanged
         * will momentarily fire with firebaseUser as null... why?
         */
        if (!existingUser && !existingUserData) {
          setUserInternal(null);
          removeItem(USER_DATA_LOCAL_KEY);
          setUserDataInternal(null);
        } else {
          setUserInternal(existingUser);
          setUserDataInternal(existingUserData);
        }
        return;
      }

      const userWithClaims = await mergeFetchedClaims(firebaseUser);
      setUserInternal({ ...userWithClaims, _fetchedFromRemote: true });
      setUserDataInternal(existingUserData);
    });
  }, []);

  useEffect(() => {
    const unsubscribeIdTokenPromise = subscribeIdTokenChange();
    const unsubscribeAuthStatePromise = subscribeAuthStateChange();

    return () => {
      unsubscribeUserDataInternal();
      unsubscribeIdTokenPromise.then((unsubscribe) => {
        return unsubscribe();
      });
      unsubscribeAuthStatePromise.then((unsubscribe) => {
        return unsubscribe();
      });
    };
  }, [
    subscribeIdTokenChange,
    subscribeAuthStateChange,
    unsubscribeUserDataInternal,
  ]);

  const user = useMemo(() => {
    return onlyIdentified(userInternal);
  }, [userInternal]);

  const userData = useMemo(() => {
    return onlyIdentified(userDataInternal);
  }, [userDataInternal]);

  const uid = useMemo(() => {
    if (isLoading(user)) {
      return undefined;
    }
    if (user === null) {
      return null;
    }
    return user.uid;
  }, [user]);

  const userFull = useMemo(() => {
    return userInternal || undefined;
  }, [userInternal]);

  const userDataFull = useMemo(() => {
    return userDataInternal || undefined;
  }, [userDataInternal]);

  const uidFull = useMemo(() => {
    return userFull?.uid;
  }, [userFull]);

  const isUserDeleted = useMemo(() => {
    return userData?.username === DEFAULT_DELETED_STRING;
  }, [userData?.username]);

  const isSubscribingUserData = useMemo(() => {
    return !!uid && isLoading(userData);
  }, [userData, uid]);

  const userIncomplete = useMemo(() => {
    return !!user && userData === null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!user, userData]);

  useEffect(() => {
    if (!userIncomplete) {
      return;
    }
    const backfill = async () => {
      const { backfillUser } = await import(
        '../firebaseCloud/user/backfillUser'
      );
      await backfillUser();
    };
    backfill();
  }, [userIncomplete]);

  const value = useMemo(() => {
    return {
      user,
      userData,
      uid,
      userFull,
      userDataFull,
      uidFull,
      isUserDeleted,
      isSubscribingUserData,
      unsubscribeUserData: unsubscribeUserDataInternal,
    };
  }, [
    user,
    userData,
    uid,
    userFull,
    userDataFull,
    uidFull,
    isUserDeleted,
    isSubscribingUserData,
    unsubscribeUserDataInternal,
  ]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const AuthProvider = memo(AuthProviderUnmemoized);
